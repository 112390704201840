@import "../libs/bootstrap/scss/bootstrap-reboot";
@import "../libs/bootstrap/scss/_utilities";
@import "../libs/bootstrap/scss/_grid";
//@import "../libs/bootstrap/scss/_navbar"
//@import "../libs/bootstrap/scss/_card"
//@import "../libs/bootstrap/scss/_dropdown"
@import "../libs/font-awesome/css/font-awesome.css";
@import "../libs/Magnific-Popup/magnific-popup";
@import "../libs/animate/animate.css";
@import "../libs/owl/assets/owl.carousel";
@import "../libs/jquery-validation-engine/css/validationEngine.jquery.css";
