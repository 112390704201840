
.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
.card {
    &-on {
        opacity: 1; }
    &-off {
        //transform: rotateY(100deg)
        opacity: 0; }
    &:hover {
        border-color: $main-color;
        .img-wrap > a {
            transform: scale(1.1); } }
    width: 260px;
    transition: all 1.2s ease;
    background-color: $header-color;
    margin: 0 12px 30px;
    color: $form-color;
    font-size: 0.9375em;
    line-height: 1.3125em;
    padding: 15px 15px 0;
    vertical-align: top;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: #1A222B 4px solid;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    h3 {
        color: $form-color;
        font-size: 1.125em;
        margin-top: 20px;
        margin-bottom: 18px; }
    p {
        margin-bottom: 0;
        margin-top: 0;
        height: 95px;
        overflow: hidden;
        strong {
            color: $main-color; } }
    .card-icon {
        width: 120px;
        height: 120px;
        line-height: 120px;
        background-color: $main-color;
        border-radius: 10em;
        display: inline-block;
        img {
            width: 75px; } }
    .card-body {
        padding: {
            top: 0;
            left: 0;
            right: 0;
            bottom: 15px; }
        overflow: hidden;
        flex: 1 1 auto; }
    .card-footer {
        margin: {
            left: -15px;
            right: -15px; }
        padding: 0.75rem 1.25rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        .btn-fallback {
            border-color: $accent;
            text-decoration: underline;
            @mixin caret-up() {}
            &:hover {
                background: none;
                text-decoration: none; } } }
    .img-wrap {
        height: 240px; }
    a {
        text-decoration: none;
        color: #E5E5E5; } }


.img-wrap {
    width: 100%;
    height: auto;
    display: inline-block;
    overflow: hidden;
    position: relative;
    > a, > div {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all .35s ease;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img {
            max-width: inherit;
            width: auto !important;
            height: 100%;
            margin-left: 50%;
            transform: translateX(-50%); } } }

.owl-carousel {
    .owl-item {
        img {
            height: 100%;
            width: unset;
            margin: 0 auto;
            transform: none; } } }
