$default-font: "Roboto", sans-serif;
$accent: #589a36;

$main-color: #589a36;
$main-color-2: #6ebd45;
$header-color: #2c3949;
$headers-color: #394b5f;
$main-font-color-b: #333;
$main-font-color-w: #fff;
$main-font-color-dw: #e5e5e5;
$form-color: #97a4b2;
$hr: #6E7B8B;
$s_gray-bottom: #D6D6D6;
$s_gray: #E5E5E5;
$section: #F9F9F9;

@mixin mix-placeholder() {
    color: $form-color;
    font-size: .85em;
    opacity: 1; }
