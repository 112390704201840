
.dropdown {
    position: relative;
    &:hover {
        .dropdown-toggle {
            color: $accent; } }
    &.active {
        a.dropdown-item {
            color: #fff;
            text-decoration: none;
            font-weight: 300;
            &:hover {
                color: $accent;
                text-decoration: underline; } } } }
.dropdown-menu {
    background: rgba(0,0,0,0.15);
    top: 80%;
    border: none;
    position: absolute;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    .dropdown-item {
        line-height: 1.8;
        padding: 0 10px;
        font-weight: 300;
        font-size: 0.9375em;
        display: block;
        width: 100%;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        &:hover {
            background: none; } } }
