@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;

        @if $asset-pipeline == true {
            src: url('#{$file-path}.eot');
            src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'); }
        @else {
            src: url('#{$file-path}.eot');
            src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'); } } }

@include font-face("PFCentroSlabProBold", "../fonts/PFCentroSlabProBold/PFCentroSlabProBold");
@include font-face("PFCentroSlabProMedium", "../fonts/PFCentroSlabProMedium/PFCentroSlabProMedium");
@include font-face("RalewayBold", "../fonts/RalewayBold/RalewayBold");
@include font-face("RalewayRegular", "../fonts/RalewayRegular/RalewayRegular");
@include font-face("RalewaySemiBold", "../fonts/RalewaySemiBold/RalewaySemiBold");

@include font-face("Roboto", "../fonts/Roboto/RobotoBold", 700);
@include font-face("Roboto", "../fonts/Roboto/RobotoLight", 300);
@include font-face("Roboto", "../fonts/Roboto/RobotoThin", 100);
@include font-face("Roboto", "../fonts/Roboto/RobotoMedium", 500);
@include font-face("Roboto", "../fonts/Roboto/RobotoRegular", 400);

@include font-face("FontAwesome", "../fonts/FontAwesome/FontAwesome", normal);
