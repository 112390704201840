
.slider {
    //margin-bottom: 25px
    perspective: 600px;
    .slide {
        text-align: center;
        //margin-bottom: 30px
        position: relative; }
    .card {
        width: auto;
        padding: 10px 20px 40px;
        margin: 0 12px;
        text-align: left;
        &:hover {
            .slide-descr, hr {
                color: $form-color; }
            h3, p {
                color: $main-font-color-dw; } }
        p, h3 {
            margin-top: 10px !important; } }
    .owl-item {
        .img-wrap, .slide-descr, h3, p, .slide-link {
            transition: all .8s ease;
            opacity: 0;
            transform: translate3d(100px, 0, 0); }
        .slide-descr, h3, .slide-link {
            transition-delay: .4s; }
        p {
            transition-delay: .5s; }
        &.active {
            .img-wrap, .slide-descr, h3, p, .slide-link {
                opacity: 1;
                transform: translate3d(0, 0, 0); } }
        h3 {
            text-transform: none;
            font-size: 1.5em;
            margin-bottom: 7px; }
        .slide-descr {
            font-size: .875em;
            color: $hr; }
        .slide-link {
            position: absolute;
            bottom: 15px;
            right: 15px; }
        p {
            margin-top: 18px; }
        hr {
            border-color: $hr; } }
    .owl-controls {
        text-align: center;
        .owl-dots {
            margin-top: 30px; }
        .owl-dot {
            width: 15px;
            height: 15px;
            margin: 0 3px;
            background-color: #ccc;
            display: inline-block;
            border-radius: 10em;
            &.active {
                background-color: $main-color; } }
        .owl-nav {
            position: absolute;
            top: -26%;
            width: 100%;
            @include media-breakpoint-down(md) {
                top: initial;
                bottom: 30px; }
            @include media-breakpoint-down(sm) {
 }                //top: -10%
            > div {
                height: 29px;
                width: 16px;
                background-image: url(../img/slider_arrows.png);
                position: absolute;
                left: 20px;
                opacity: .5;
                transition: all .25s ease;
                transform: scale(1.2);
                &:hover {
                    opacity: 1; } }
            .owl-next {
                right: 20px;
                left: auto;
                background-position: -16px 0; } } } }

//.slider-news
//    //@extend .slider
//    opacity: 0
//    .slide
//        text-align: left
//        margin-right: 5px
//        float: left
//        &:last-child
//            margin-right: 0
//        .slide-wrap
//            overflow: hidden
//            max-height: 180px
//        .img-wrap
//            width: 100%
//            height: auto
//            margin: 0
//            box-shadow: none
//            border: 2px solid $main-color
//            border-bottom-width: 4px
//            border-radius: 2px
//        .slide-header, .slide-text
//            color: $main-font-color-w
//            font-weight: 300
//        .slide-text
//            font-size: .9em
//            margin-top: 10px
//        .slide-header, .slide-descr
//            white-space: nowrap
//        .slide-header
//            font-size: 1.4em
//            margin-bottom: 5px
//            a
//                color: inherit
//                text-decoration: inherit
//        .slide-read
//            position: absolute
//            bottom: -30px
//            right: 0
//        .slide-descr
//            color: #8694a7
//            .fa
//                margin-right: 5px
//    &.slider-show
//        opacity: 1
//    .owl-controls
//        .owl-nav
//            top: 32%
