
.main-footer {
    @extend .text-shadow-1;
    @extend .section;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    position: relative;
    padding-top: 65px;
    &::before {
        @extend .before;
        background-color: #394b5f;
        opacity: .85; }
    .section-head {
        text-align: left;
        h2, p {
            color: $main-font-color-w; }
        h2 {
            font-weight: 300; }
        h4 {
            @extend p; }
        p {
            font-weight: 200; } }
    .footer-items {
        opacity: 0;
        transform: translateY(-25px);
        text-align: left;
        &.on {
            opacity: 1;
            transform: translateY(0); } }
    .footer-item {
        position: relative;
        p, span {
            display: inline-block;
            color: $main-font-color-dw; }
        span {
            width: 30px;
            margin-right: 5px; }
        .fa {
            display: block;
            text-align: center; }
        address {
            @extend p; } } }

.footer-line {
    background-color: #1F2B3A;
    color: white;
    padding: 25px 0;
    margin-top: 60px;
    @include media-breakpoint-up(md) {
        padding: 15px 0; }
    .top {
        background-image: url(../img/icons/top.png);
        background-repeat: no-repeat;
        background-position: 100%;
        cursor: pointer;
        display: inline-block;
        z-index: 1;
        span {
            padding-right: 25px; } }
    [class^=col-] {
        //white-space: normal
        span {
            white-space: nowrap;
            display: inline-block; } }
    .logo {
        img {
            width: 100px; } } }

.rockdata {
    margin-right: 50px;
    text-decoration: none;
    color: inherit;
    &:hover {
        color: $main-color-2;
        .icon-arrow {
            animation: preloader .4s infinite ease;
            transform: translateY(7px); } } }

.icon-arrow {
    margin-right: 5px;
    background-image: url(../img/icons/koza-50.png);
    background-size: cover;
    width: 18px;
    height: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: top; }

@keyframes preloader {
    to {
        transform: translateY(-4px) scale(1.5); } }
