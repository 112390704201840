@import "fonts";
@import "libs";
@import "vars";

@import "_header";
@import "_footer";
@import "_tools";
@import "_sliders";
@import "_buttons";
@import "_dropdown";
@import "_cards";
@import "_forms";

html, body {
    height: 100%;
    margin: 0 !important; }

body {
    font-size: 1em;
    min-width: 300px;
    position: relative;
    line-height: 1.375em;
    font-family: $default-font;
    overflow-x: hidden;
    color: $main-font-color-b;
    input, textarea {
        outline: none; } }

a {
    text-decoration: underline;
    &:hover, &:focus, &:active {
        text-decoration: none; } }

a, a:hover, a:focus, a:active {
    color: $main-color-2;
    outline: none; }

h1, h2, h3, h4, h5, h6 {
    @extend .headers;
    line-height: 1.375em; }

h1 {
    font-size: 3em; }

h2 {
    font-size: 2em;
    @include media-breakpoint-down(sm) {
        font-size: 1.8em; } }

h3 {
    font-size: 1.625em; }

.headers {
    text-transform: uppercase;
    font-weight: normal;
    color: $header-color; }

.hidden {
    display: none; }

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto; }

.text-shadow-1 {
    text-shadow: 0 1px 2px rgba(0,0,0,0.6); }

.section {
    padding-top: 65px;
    background-color: $section;
    position: relative;
    a {
        &:hover {
            text-decoration: none; } }
    .section-head {
        margin-bottom: 65px;
        p, .p {
            margin-top: 50px;
            text-align: center;
            animation-delay: .4s;
            a {
                font-family: "RalewayBold", sans-serif; } }
        h2 {
            text-align: center;
            position: relative;
            display: inline-block;
            &::after {
                position: absolute;
                content: '';
                height: 4px;
                background-color: $main-color;
                width: 100%;
                display: block;
                bottom: -5px;
                left: 50%;
                transform: translate(-50%, 0); } }
        h3 {
            text-align: center;
            margin-top: 50px; } }

    .section-bottom {
        background-color: $s_gray-bottom;
        padding: 50px 0;
        text-align: center;
        .buttons {
            font-size: 1.25em; } } }

.section-head {
    width: 100%;
    text-align: center; }

.section-left {
    .section-head {
        margin-bottom: 50px;
        h2 {
            margin-top: 0;
            text-align: left;
            &::after {
                transform: none;
                left: 0; } }
        p {
            text-align: left; } } }

.section_profit {
    padding-top: 0;
    .section-head {
        margin-top: 65px; } }

.section-news, .section_tools {
    .section-content {
        background-image: url(../img/s1_gray_bg.png);
        background-repeat: no-repeat;
        background-position: bottom; }
    .section-bottom {
        background-color: #2C3949; } }
.section_tools {
  .col-img {
    img {
      width: 100%;
      height: auto; } } }
.section-content {
    position: relative;
    padding-bottom: 90px; }

.profit-items {
    padding-bottom: 20px;
    .profit-item {
        text-align: center;
        color: #111;
        margin-bottom: 75px;
        display: block;
        transition: all .25s ease;

        &:hover {
            color: $main-color;
            h4 {
                color: $main-color; } }
        > span {
            display: block; }
        h4 {
            font-weight: 600; }
        &-text {
            //font-family: "RalewayBold", sans-serif
            font-size: 0.875em;
            line-height: 1.25em;
            font-weight: 500; } }
    .img-wrap {
        padding-bottom: 32px;
        margin-bottom: 15px;
        display: block;
        position: relative;
        line-height: 60px;
        height: 100px;
        &::before {
            content: '';
            position: absolute;
            height: 1px;
            background-color: $main-color;
            width: 58px;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0); }
        img {
            max-width: 100px;
            max-height: 80px;
            width: auto; } } }

.img-wrap {
    .img-fluid {
        width: 100%;
        max-width: 100%; } }

.profit-items-container {
    text-align: center;
    padding-top: 25px; }

.profit-item-wrap {
    display: inline-block;
    float: none;
    padding: 0 15px;
    //width: 365px
    vertical-align: top;
    opacity: 0;
    transition: all .5s ease;
    transform: translateY(-25px);
    &.on {
        opacity: 1;
        transform: translateY(0); }
    &:hover {
        transform: translateY(-10px); }
    @include media-breakpoint-up(xl) {
        width: 365px; } }

.section_clients {
    .img-wrap {
        height: 140px;
        img {
            max-width: 260px;
            max-height: 100px;
            position: relative;
            top: 50%;
            transform: translateY(-50%); } }
    .clients-items-container {
        padding-top: 50px;
        text-align: center; }
    .clients-item-wrap {
        @extend .profit-item-wrap;
        &:hover {
            transform: none; } } }

.s_dark {
    background-color: $headers-color;
    .section-head {
        h2 {
            color: $main-font-color-w;
            font-weight: 200; }
        p {
            color: $main-font-color-dw; } }
    .section-left {
        //.section-head, .section-head p, p
        //    text-align: left
        p {
            color: $main-font-color-dw; } }
    .section-bottom {
        background-color: $header-color;
        margin-top: 60px; }
    .profit-item {
        color: $main-font-color-dw;
        //h4, span
 } }        //  //color: $main-font-color-dw

.s_gray {
    background-color: $s_gray;
    //.section-left
    //    .section-head, .section-head p, p
    //        text-align: left
    .section-bottom {
        background-color: $s_gray-bottom;
        margin-top: 60px; } }

.section-right {
    .section-head, .section-head p, p {
        text-align: right; }
    padding: {
        top: 30px; }
    @include media-breakpoint-up(md) {
        padding-top: inherit; }
    li {
        &:after {
            right: -5px;
            content:  "—";
            position: relative;
            color: $main-color; } } }


.section-left {
    .section-head, .section-head p, p {
        text-align: left; }
    padding: {
        bottom: 30px; }
    li {
        &:before {
            left: -5px;
            content:  "—";
            position: relative;
            color: $main-color; } }
    @include media-breakpoint-up(md) {
        padding-left: 40px; } }

.section-pb {
    padding-bottom: 70px; }

.section_scheme {
    &.s_dark {
        background-color: $headers-color; }
    .scheme-items {
        padding-bottom: 50px;
        @include media-breakpoint-up(md) {
            padding-bottom: 0; } }
    .img-wrap {
        height: auto; } }

@media only screen and (max-width : 768px) {

    /*Disable Animation on Mobile Devices*/
    .animated {
        transition-property: none !important;
        transform: none !important;
        animation: none !important; } }

@media only screen and (max-width : 992px) {

    .header-wrap {
        margin-top: 0;
        h1 {
            margin-top: 0; } }

    .main-menu {
        top: 50px; }

    .top-line {
        min-height: 140px; } }
