.owl-carousel {
	.animated {
		-webkit-animation-duration: 1000ms;
		animation-duration: 1000ms;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both; }
	.owl-animated-in {
		z-index: 0; }
	.owl-animated-out {
		z-index: 1; }
	.fadeOut {
		-webkit-animation-name: fadeOut;
		animation-name: fadeOut; } }

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1; }

	100% {
		opacity: 0; } }


@keyframes fadeOut {
	0% {
		opacity: 1; }

	100% {
		opacity: 0; } }


.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out; }


.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	z-index: 1;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0; } }
	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0px, 0px, 0px); }
	.owl-controls {
		.owl-nav {
			.owl-prev, .owl-next {
				cursor: pointer;
				cursor: hand;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none; } }
		.owl-dot {
			cursor: pointer;
			cursor: hand;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none; } }
	&.owl-loaded {
		display: block; }
	&.owl-loading {
		opacity: 0;
		display: block; }
	&.owl-hidden {
		opacity: 0; }
	.owl-refresh .owl-item {
		display: none; }
	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		img {
			display: block;
			width: 100%;
			-webkit-transform-style: preserve-3d; } }
	&.owl-text-select-on .owl-item {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto; }
	.owl-grab {
		cursor: move;
		cursor: -webkit-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab; }
	&.owl-rtl {
		direction: rtl;
		.owl-item {
			float: right; } } }


.no-js .owl-carousel {
	display: block; }

.owl-carousel {
	.owl-item {
		.owl-lazy {
			opacity: 0;
			-webkit-transition: opacity 400ms ease;
			-moz-transition: opacity 400ms ease;
			-ms-transition: opacity 400ms ease;
			-o-transition: opacity 400ms ease;
			transition: opacity 400ms ease; }
		img {
			transform-style: preserve-3d; } }
	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: #000; }
	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		background: url("owl.video.play.png") no-repeat;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		-webkit-transition: scale 100ms ease;
		-moz-transition: scale 100ms ease;
		-ms-transition: scale 100ms ease;
		-o-transition: scale 100ms ease;
		transition: scale 100ms ease;
		&:hover {
			-webkit-transition: scale(1.3, 1.3);
			-moz-transition: scale(1.3, 1.3);
			-ms-transition: scale(1.3, 1.3);
			-o-transition: scale(1.3, 1.3);
			transition: scale(1.3, 1.3); } }
	.owl-video-playing {
		.owl-video-tn, .owl-video-play-icon {
			display: none; } }
	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		-moz-background-size: contain;
		-o-background-size: contain;
		background-size: contain;
		-webkit-transition: opacity 400ms ease;
		-moz-transition: opacity 400ms ease;
		-ms-transition: opacity 400ms ease;
		-o-transition: opacity 400ms ease;
		transition: opacity 400ms ease; }
	.owl-video-frame {
		position: relative;
		z-index: 1; } }
