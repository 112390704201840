@charset "UTF-8";
@import url(../libs/font-awesome/css/font-awesome.css);
@import url(../libs/animate/animate.css);
@import url(../libs/jquery-validation-engine/css/validationEngine.jquery.css);
@font-face {
  font-family: "PFCentroSlabProBold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/PFCentroSlabProBold/PFCentroSlabProBold.eot");
  src: url("../fonts/PFCentroSlabProBold/PFCentroSlabProBold.eot?#iefix") format("embedded-opentype"), url("../fonts/PFCentroSlabProBold/PFCentroSlabProBold.woff") format("woff"), url("../fonts/PFCentroSlabProBold/PFCentroSlabProBold.ttf") format("truetype"); }

@font-face {
  font-family: "PFCentroSlabProMedium";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/PFCentroSlabProMedium/PFCentroSlabProMedium.eot");
  src: url("../fonts/PFCentroSlabProMedium/PFCentroSlabProMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/PFCentroSlabProMedium/PFCentroSlabProMedium.woff") format("woff"), url("../fonts/PFCentroSlabProMedium/PFCentroSlabProMedium.ttf") format("truetype"); }

@font-face {
  font-family: "RalewayBold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RalewayBold/RalewayBold.eot");
  src: url("../fonts/RalewayBold/RalewayBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RalewayBold/RalewayBold.woff") format("woff"), url("../fonts/RalewayBold/RalewayBold.ttf") format("truetype"); }

@font-face {
  font-family: "RalewayRegular";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RalewayRegular/RalewayRegular.eot");
  src: url("../fonts/RalewayRegular/RalewayRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/RalewayRegular/RalewayRegular.woff") format("woff"), url("../fonts/RalewayRegular/RalewayRegular.ttf") format("truetype"); }

@font-face {
  font-family: "RalewaySemiBold";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/RalewaySemiBold/RalewaySemiBold.eot");
  src: url("../fonts/RalewaySemiBold/RalewaySemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/RalewaySemiBold/RalewaySemiBold.woff") format("woff"), url("../fonts/RalewaySemiBold/RalewaySemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Roboto/RobotoBold.eot");
  src: url("../fonts/Roboto/RobotoBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/RobotoBold.woff") format("woff"), url("../fonts/Roboto/RobotoBold.ttf") format("truetype"); }

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Roboto/RobotoLight.eot");
  src: url("../fonts/Roboto/RobotoLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/RobotoLight.woff") format("woff"), url("../fonts/Roboto/RobotoLight.ttf") format("truetype"); }

@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Roboto/RobotoThin.eot");
  src: url("../fonts/Roboto/RobotoThin.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/RobotoThin.woff") format("woff"), url("../fonts/Roboto/RobotoThin.ttf") format("truetype"); }

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Roboto/RobotoMedium.eot");
  src: url("../fonts/Roboto/RobotoMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/RobotoMedium.woff") format("woff"), url("../fonts/Roboto/RobotoMedium.ttf") format("truetype"); }

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Roboto/RobotoRegular.eot");
  src: url("../fonts/Roboto/RobotoRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/RobotoRegular.woff") format("woff"), url("../fonts/Roboto/RobotoRegular.ttf") format("truetype"); }

@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/FontAwesome/FontAwesome.eot");
  src: url("../fonts/FontAwesome/FontAwesome.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/FontAwesome.woff") format("woff"), url("../fonts/FontAwesome/FontAwesome.ttf") format("truetype"); }

/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p, .main-footer .section-head h4, .main-footer .footer-item address {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
    .owl-carousel .owl-stage:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-controls .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel .owl-refresh .owl-item {
    display: none; }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .owl-carousel .owl-item img {
      display: block;
      width: 100%;
      -webkit-transform-style: preserve-3d; }
  .owl-carousel.owl-text-select-on .owl-item {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto; }
  .owl-carousel .owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
    .owl-carousel.owl-rtl .owl-item {
      float: right; }

.no-js .owl-carousel {
  display: block; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease; }
  .owl-carousel .owl-video-play-icon:hover {
    -webkit-transition: scale(1.3, 1.3);
    -moz-transition: scale(1.3, 1.3);
    -ms-transition: scale(1.3, 1.3);
    -o-transition: scale(1.3, 1.3);
    transition: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1; }

.before, .main-head::before, .main-footer::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.trans {
  transition: all .5s ease; }

.main-head {
  min-height: 620px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (min-width: 480px) {
    .main-head {
      height: 100vh; } }
  .main-head::before {
    background-color: #394b5f;
    opacity: .5; }
  .main-head h1 {
    text-transform: none;
    line-height: 1.125em; }

.top-line {
  padding-top: 20px;
  min-height: 80px;
  background: linear-gradient(black, rgba(255, 0, 0, 0)); }
  .top-line .row {
    position: relative; }
  @media (max-width: 767.98px) {
    .top-line {
      padding-top: 10px; } }

.logo {
  opacity: .95; }
  .logo img {
    width: 200px; }
    @media only screen and (max-width: 992px) and (min-width: 768px) {
      .logo img {
        width: 150px; } }
    @media only screen and (max-width: 576px) {
      .logo img {
        margin-top: 30px;
        margin-bottom: 30px; } }

@media only screen and (max-width: 768px) {
  .main-menu {
    display: none;
    float: right;
    min-width: 295px;
    text-align: right;
    background-color: rgba(44, 57, 73, 0.8);
    top: 10px;
    border: #111821 1px solid;
    position: absolute;
    right: 15px;
    z-index: 2; }
    .main-menu ul {
      padding-top: 50px !important; }
      .main-menu ul li {
        display: block;
        line-height: 0;
        padding: 0; }
        .main-menu ul li.active a {
          background-color: #273646;
          text-decoration: none;
          font-family: "Roboro", sans-serif;
          font-weight: 700; }
      .main-menu ul a {
        display: block;
        line-height: 48px;
        background-color: transparent;
        font-size: 0.9375em;
        text-transform: uppercase;
        transition: all .5s ease;
        border-bottom: #222C36 1px solid;
        padding: 10px !important; }
        .main-menu ul a:hover {
          background-color: #273646;
          text-decoration: none; } }

.menu-style {
  font-size: 0.9375em; }
  .menu-style li {
    display: inline-block; }
    .menu-style li.active a {
      color: #6ebd45;
      text-decoration: underline; }
    .menu-style li a {
      transition: all .5s ease;
      line-height: 2.5;
      padding: 15px; }
      .menu-style li a:hover {
        color: #589a36;
        text-decoration: underline; }
  .menu-style ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex; }
  .menu-style a {
    text-decoration: none;
    color: #fff; }
  @media only screen and (max-width: 992px) and (min-width: 768px) {
    .menu-style {
      font-size: .8em; }
      .menu-style ul {
        justify-content: flex-start; } }

.main-side-menu li {
  order: 1; }

@media only screen and (max-width: 576px) {
  .main-side-menu .menu-login {
    order: 0; } }

.lang-switch {
  padding: 0 15px;
  z-index: 100;
  margin-top: 25px; }
  @media (min-width: 992px) {
    .lang-switch {
      position: absolute;
      bottom: -80%;
      right: 0; } }
  @media (min-width: 576px) {
    .lang-switch {
      margin-top: 10px; } }
  @media (min-width: 768px) {
    .lang-switch {
      margin-top: 20px; } }
  .lang-switch li a {
    font-weight: 500; }
  .lang-switch li.active a {
    text-decoration: none; }
    .lang-switch li.active a:hover {
      text-decoration: underline; }

.toggle-mnu-wrap {
  position: relative; }
  .toggle-mnu-wrap .toggle-mnu {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    line-height: 48px; }
    @media (max-width: 767.98px) {
      .toggle-mnu-wrap .toggle-mnu .toggle-mnu {
        line-height: 34px;
        margin-top: 8px; } }

.toggle-mnu {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: 14px; }

.toggle-mnu span:after,
.toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px; }

.toggle-mnu span:after {
  top: 18px; }

.toggle-mnu span {
  position: relative;
  display: block; }

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s;
  backface-visibility: hidden;
  border-radius: 2px; }

.toggle-mnu.on span {
  background-color: transparent; }

.toggle-mnu.on span:before {
  transform: rotate(45deg) translate(-1px, 0px); }

.toggle-mnu.on span:after {
  transform: rotate(-45deg) translate(6px, -7px); }

.toggle-mnu.on + #menu {
  opacity: 1;
  visibility: visible; }

.header-wrap {
  display: flex; }
  .header-wrap .header-left {
    position: relative;
    z-index: 0;
    padding-top: 30px;
    padding-bottom: 30px; }
    .header-wrap .header-left .btn-fallback {
      background-color: #589a36; }
      .header-wrap .header-left .btn-fallback:hover {
        background-color: transparent; }
    .header-wrap .header-left:after {
      content: '';
      background: linear-gradient(to right, #394b5f, rgba(57, 75, 95, 0.8), rgba(57, 75, 95, 0.65));
      opacity: 0.9;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      filter: blur(30px); }
  .header-wrap .header-title, .header-wrap .header-subtitle {
    color: #fff; }
  .header-wrap .header-title {
    padding-bottom: 8px;
    font-weight: 100;
    font-size: 2.7em; }
    @media (min-width: 992px) {
      .header-wrap .header-title {
        font-size: 2.9em; } }
    .header-wrap .header-title strong {
      font-weight: 500;
      transform: translate3d(0, -100px, 0); }
      .header-wrap .header-title strong.anim {
        transition-delay: .2s; }
    .header-wrap .header-title span {
      transform: translate3d(0, -150px, 0); }
      .header-wrap .header-title span:first-child {
        font-size: 120%; }
      @media (min-width: 992px) {
        .header-wrap .header-title span:first-child {
          font-size: 150%; } }
      .header-wrap .header-title span.anim {
        transition-delay: .4s; }
    .header-wrap .header-title strong, .header-wrap .header-title span {
      transition: transform 1s ease, opacity 1s ease;
      display: inline-block;
      opacity: 0; }
      .header-wrap .header-title strong.anim, .header-wrap .header-title span.anim {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
    .header-wrap .header-title::after {
      position: relative;
      content: '';
      height: 6px;
      background-color: #589a36;
      width: 100%;
      display: block;
      margin-top: 15px; }
  .header-wrap .button-wrap {
    opacity: 0;
    transform: translate3d(0, 150px, 0);
    transition: transform 1.4s ease, opacity 1.4s ease; }
    .header-wrap .button-wrap.anim {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-delay: .8s; }
  .header-wrap .header-subtitle {
    font-weight: 300;
    transition: transform 1s ease, opacity 1s ease;
    transform: translate3d(0, 80px, 0);
    opacity: 0; }
    .header-wrap .header-subtitle.anim {
      opacity: 1;
      transition-delay: .6s;
      transform: translate3d(0, 0, 0); }
  .header-wrap p, .header-wrap .main-footer .section-head h4, .main-footer .section-head .header-wrap h4, .header-wrap .main-footer .footer-item address, .main-footer .footer-item .header-wrap address {
    color: #fff;
    margin-top: 10px;
    font-weight: 300;
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    transform: translate3d(0, 80px, 0); }
    .header-wrap p a, .header-wrap .main-footer .section-head h4 a, .main-footer .section-head .header-wrap h4 a, .header-wrap .main-footer .footer-item address a, .main-footer .footer-item .header-wrap address a {
      font-weight: 500; }
    .header-wrap p.anim, .header-wrap .main-footer .section-head h4.anim, .main-footer .section-head .header-wrap h4.anim, .header-wrap .main-footer .footer-item address.anim, .main-footer .footer-item .header-wrap address.anim {
      opacity: 1;
      transition-delay: .8s;
      transform: translate3d(0, 0, 0); }
  .header-wrap .buttons {
    padding: 12px 50px; }

.arrow-wrap {
  text-align: center;
  z-index: 2;
  width: 100%;
  position: relative;
  padding: 2.4em 0 4em; }

.arrow-bottom {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url(../img/arrow_down.png);
  cursor: pointer;
  opacity: .7;
  transition: opacity .25s ease; }
  @media (max-width: 768px) {
    .arrow-bottom {
      display: none; } }
  .arrow-bottom:hover {
    opacity: 1; }

.main-footer {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: relative;
  padding-top: 65px; }
  .main-footer::before {
    background-color: #394b5f;
    opacity: .85; }
  .main-footer .section-head {
    text-align: left; }
    .main-footer .section-head h2, .main-footer .section-head p, .main-footer .section-head h4, .main-footer .section-head .footer-item address, .main-footer .footer-item .section-head address {
      color: #fff; }
    .main-footer .section-head h2 {
      font-weight: 300; }
    .main-footer .section-head p, .main-footer .section-head h4, .main-footer .section-head .footer-item address, .main-footer .footer-item .section-head address {
      font-weight: 200; }
  .main-footer .footer-items {
    opacity: 0;
    transform: translateY(-25px);
    text-align: left; }
    .main-footer .footer-items.on {
      opacity: 1;
      transform: translateY(0); }
  .main-footer .footer-item {
    position: relative; }
    .main-footer .footer-item p, .main-footer .footer-item .section-head h4, .main-footer .section-head .footer-item h4, .main-footer .footer-item address, .main-footer .footer-item span {
      display: inline-block;
      color: #e5e5e5; }
    .main-footer .footer-item span {
      width: 30px;
      margin-right: 5px; }
    .main-footer .footer-item .fa {
      display: block;
      text-align: center; }

.footer-line {
  background-color: #1F2B3A;
  color: white;
  padding: 25px 0;
  margin-top: 60px; }
  @media (min-width: 768px) {
    .footer-line {
      padding: 15px 0; } }
  .footer-line .top {
    background-image: url(../img/icons/top.png);
    background-repeat: no-repeat;
    background-position: 100%;
    cursor: pointer;
    display: inline-block;
    z-index: 1; }
    .footer-line .top span {
      padding-right: 25px; }
  .footer-line [class^=col-] span {
    white-space: nowrap;
    display: inline-block; }
  .footer-line .logo img {
    width: 100px; }

.rockdata {
  margin-right: 50px;
  text-decoration: none;
  color: inherit; }
  .rockdata:hover {
    color: #6ebd45; }
    .rockdata:hover .icon-arrow {
      animation: preloader .4s infinite ease;
      transform: translateY(7px); }

.icon-arrow {
  margin-right: 5px;
  background-image: url(../img/icons/koza-50.png);
  background-size: cover;
  width: 18px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  vertical-align: top; }

@keyframes preloader {
  to {
    transform: translateY(-4px) scale(1.5); } }

.tools .main-head {
  min-height: 300px;
  height: initial; }

.tools .tools-head {
  margin-bottom: 20px; }
  .tools .tools-head [class^="col"]:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center; }

.tools .header-wrap p.anim, .tools .header-wrap .main-footer .section-head h4.anim, .main-footer .section-head .tools .header-wrap h4.anim, .tools .header-wrap .main-footer .footer-item address.anim, .main-footer .footer-item .tools .header-wrap address.anim {
  transition-delay: .5s; }

.tools .card {
  color: #333;
  background-color: #E5E5E5; }
  .tools .card h3 {
    color: #2c3949; }
  .tools .card a {
    font-weight: bold;
    color: #589a36; }

.tools .profit-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .tools .profit-items-container .profit-item-wrap, .tools .profit-items-container .section_clients .clients-item-wrap, .section_clients .tools .profit-items-container .clients-item-wrap {
    width: 55%; }
    @media (min-width: 576px) {
      .tools .profit-items-container .profit-item-wrap, .tools .profit-items-container .section_clients .clients-item-wrap, .section_clients .tools .profit-items-container .clients-item-wrap {
        width: 40%; } }
    @media (min-width: 768px) {
      .tools .profit-items-container .profit-item-wrap, .tools .profit-items-container .section_clients .clients-item-wrap, .section_clients .tools .profit-items-container .clients-item-wrap {
        width: 25%; } }
    .tools .profit-items-container .profit-item-wrap .img-wrap, .tools .profit-items-container .section_clients .clients-item-wrap .img-wrap, .section_clients .tools .profit-items-container .clients-item-wrap .img-wrap {
      height: 90px; }
    .tools .profit-items-container .profit-item-wrap img, .tools .profit-items-container .section_clients .clients-item-wrap img, .section_clients .tools .profit-items-container .clients-item-wrap img {
      max-height: 70px; }
    .tools .profit-items-container .profit-item-wrap span + span, .tools .profit-items-container .section_clients .clients-item-wrap span + span, .section_clients .tools .profit-items-container .clients-item-wrap span + span {
      max-width: 170px;
      margin: 0 auto; }
      @media (max-width: 991.98px) {
        .tools .profit-items-container .profit-item-wrap span + span, .tools .profit-items-container .section_clients .clients-item-wrap span + span, .section_clients .tools .profit-items-container .clients-item-wrap span + span {
          max-width: initial; } }

.tools-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 5px; }
  .tools-items h4, .tools-items h3 {
    font-weight: 500;
    border-bottom: 1px solid #589a36;
    color: #589a36;
    /* text-transform: initial; */
    margin-bottom: 10px;
    padding-bottom: 10px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1); }
  .tools-items p, .tools-items .main-footer .section-head h4, .main-footer .section-head .tools-items h4, .tools-items .main-footer .footer-item address, .main-footer .footer-item .tools-items address, .tools-items ul {
    margin-bottom: .5rem; }
  .tools-items i.fa {
    color: #589a36; }
  .tools-items ul {
    list-style: none;
    list-style-position: outside;
    padding-left: 5px;
    padding-right: 5px; }
  .tools-items strong {
    color: #589a36; }
  .tools-items.s_dark {
    background-color: #394b5f; }

.s_dark .tools-items h4, .s_dark .tools-items h3 {
  color: #6ebd45; }

.s_dark .tools-items p, .s_dark .tools-items .main-footer .section-head h4, .main-footer .section-head .s_dark .tools-items h4, .s_dark .tools-items .main-footer .footer-item address, .main-footer .footer-item .s_dark .tools-items address {
  color: #e5e5e5;
  font-weight: 300; }

.slider {
  perspective: 600px; }
  .slider .slide {
    text-align: center;
    position: relative; }
  .slider .card {
    width: auto;
    padding: 10px 20px 40px;
    margin: 0 12px;
    text-align: left; }
    .slider .card:hover .slide-descr, .slider .card:hover hr {
      color: #97a4b2; }
    .slider .card:hover h3, .slider .card:hover p, .slider .card:hover .main-footer .section-head h4, .main-footer .section-head .slider .card:hover h4, .slider .card:hover .main-footer .footer-item address, .main-footer .footer-item .slider .card:hover address {
      color: #e5e5e5; }
    .slider .card p, .slider .card .main-footer .section-head h4, .main-footer .section-head .slider .card h4, .slider .card .main-footer .footer-item address, .main-footer .footer-item .slider .card address, .slider .card h3 {
      margin-top: 10px !important; }
  .slider .owl-item .img-wrap, .slider .owl-item .slide-descr, .slider .owl-item h3, .slider .owl-item p, .slider .owl-item .main-footer .section-head h4, .main-footer .section-head .slider .owl-item h4, .slider .owl-item .main-footer .footer-item address, .main-footer .footer-item .slider .owl-item address, .slider .owl-item .slide-link {
    transition: all .8s ease;
    opacity: 0;
    transform: translate3d(100px, 0, 0); }
  .slider .owl-item .slide-descr, .slider .owl-item h3, .slider .owl-item .slide-link {
    transition-delay: .4s; }
  .slider .owl-item p, .slider .owl-item .main-footer .section-head h4, .main-footer .section-head .slider .owl-item h4, .slider .owl-item .main-footer .footer-item address, .main-footer .footer-item .slider .owl-item address {
    transition-delay: .5s; }
  .slider .owl-item.active .img-wrap, .slider .owl-item.active .slide-descr, .slider .owl-item.active h3, .slider .owl-item.active p, .slider .owl-item.active .main-footer .section-head h4, .main-footer .section-head .slider .owl-item.active h4, .slider .owl-item.active .main-footer .footer-item address, .main-footer .footer-item .slider .owl-item.active address, .slider .owl-item.active .slide-link {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .slider .owl-item h3 {
    text-transform: none;
    font-size: 1.5em;
    margin-bottom: 7px; }
  .slider .owl-item .slide-descr {
    font-size: .875em;
    color: #6E7B8B; }
  .slider .owl-item .slide-link {
    position: absolute;
    bottom: 15px;
    right: 15px; }
  .slider .owl-item p, .slider .owl-item .main-footer .section-head h4, .main-footer .section-head .slider .owl-item h4, .slider .owl-item .main-footer .footer-item address, .main-footer .footer-item .slider .owl-item address {
    margin-top: 18px; }
  .slider .owl-item hr {
    border-color: #6E7B8B; }
  .slider .owl-controls {
    text-align: center; }
    .slider .owl-controls .owl-dots {
      margin-top: 30px; }
    .slider .owl-controls .owl-dot {
      width: 15px;
      height: 15px;
      margin: 0 3px;
      background-color: #ccc;
      display: inline-block;
      border-radius: 10em; }
      .slider .owl-controls .owl-dot.active {
        background-color: #589a36; }
    .slider .owl-controls .owl-nav {
      position: absolute;
      top: -26%;
      width: 100%; }
      @media (max-width: 991.98px) {
        .slider .owl-controls .owl-nav {
          top: initial;
          bottom: 30px; } }
      .slider .owl-controls .owl-nav > div {
        height: 29px;
        width: 16px;
        background-image: url(../img/slider_arrows.png);
        position: absolute;
        left: 20px;
        opacity: .5;
        transition: all .25s ease;
        transform: scale(1.2); }
        .slider .owl-controls .owl-nav > div:hover {
          opacity: 1; }
      .slider .owl-controls .owl-nav .owl-next {
        right: 20px;
        left: auto;
        background-position: -16px 0; }

.buttons {
  display: inline-block;
  border: none;
  background-color: #589a36;
  color: #fff;
  font-size: 1.2em;
  padding: 12px 30px;
  border-bottom: #2c3949 3px solid;
  transition: all .25s ease;
  text-decoration: none;
  border-radius: 2px; }
  .buttons:hover, .buttons:active, .buttons:focus {
    text-decoration: none;
    background-color: #394b5f;
    color: #fff;
    border-bottom-color: #589a36; }

.btn-fallback {
  border: 1px solid white;
  text-decoration: none;
  color: white;
  background: none;
  font-size: inherit;
  transition: all 0.25s ease-in;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  padding: 8px 20px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none; }
  .btn-fallback:hover {
    background-color: #589a36;
    color: white; }
  .btn-fallback .fa {
    margin-right: 7px; }

.button-wrap {
  animation-delay: .2s;
  animation-duration: .5s; }

.dropdown {
  position: relative; }
  .dropdown:hover .dropdown-toggle {
    color: #589a36; }
  .dropdown.active a.dropdown-item {
    color: #fff;
    text-decoration: none;
    font-weight: 300; }
    .dropdown.active a.dropdown-item:hover {
      color: #589a36;
      text-decoration: underline; }

.dropdown-menu {
  background: rgba(0, 0, 0, 0.15);
  top: 80%;
  border: none;
  position: absolute;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0; }
  .dropdown-menu .dropdown-item {
    line-height: 1.8;
    padding: 0 10px;
    font-weight: 300;
    font-size: 0.9375em;
    display: block;
    width: 100%;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent; }
    .dropdown-menu .dropdown-item:hover {
      background: none; }

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }

.card {
  width: 260px;
  transition: all 1.2s ease;
  background-color: #2c3949;
  margin: 0 12px 30px;
  color: #97a4b2;
  font-size: 0.9375em;
  line-height: 1.3125em;
  padding: 15px 15px 0;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: #1A222B 4px solid;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem; }
  .card-on {
    opacity: 1; }
  .card-off {
    opacity: 0; }
  .card:hover {
    border-color: #589a36; }
    .card:hover .img-wrap > a {
      transform: scale(1.1); }
  .card h3 {
    color: #97a4b2;
    font-size: 1.125em;
    margin-top: 20px;
    margin-bottom: 18px; }
  .card p, .card .main-footer .section-head h4, .main-footer .section-head .card h4, .card .main-footer .footer-item address, .main-footer .footer-item .card address {
    margin-bottom: 0;
    margin-top: 0;
    height: 95px;
    overflow: hidden; }
    .card p strong, .card .main-footer .section-head h4 strong, .main-footer .section-head .card h4 strong, .card .main-footer .footer-item address strong, .main-footer .footer-item .card address strong {
      color: #589a36; }
  .card .card-icon {
    width: 120px;
    height: 120px;
    line-height: 120px;
    background-color: #589a36;
    border-radius: 10em;
    display: inline-block; }
    .card .card-icon img {
      width: 75px; }
  .card .card-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
    overflow: hidden;
    flex: 1 1 auto; }
  .card .card-footer {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
    .card .card-footer .btn-fallback {
      border-color: #589a36;
      text-decoration: underline; }
      .card .card-footer .btn-fallback:hover {
        background: none;
        text-decoration: none; }
  .card .img-wrap {
    height: 240px; }
  .card a {
    text-decoration: none;
    color: #E5E5E5; }

.img-wrap {
  width: 100%;
  height: auto;
  display: inline-block;
  overflow: hidden;
  position: relative; }
  .img-wrap > a, .img-wrap > div {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all .35s ease;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .img-wrap > a img, .img-wrap > div img {
      max-width: inherit;
      width: auto !important;
      height: 100%;
      margin-left: 50%;
      transform: translateX(-50%); }

.owl-carousel .owl-item img {
  height: 100%;
  width: unset;
  margin: 0 auto;
  transform: none; }

*::-webkit-input-placeholder {
  color: #97a4b2;
  font-size: .85em;
  opacity: 1; }

*:-moz-placeholder {
  color: #97a4b2;
  font-size: .85em;
  opacity: 1; }

*::-moz-placeholder {
  color: #97a4b2;
  font-size: .85em;
  opacity: 1; }

*:-ms-input-placeholder {
  color: #97a4b2;
  font-size: .85em;
  opacity: 1; }

.forms {
  background-color: #2c3949;
  padding: 35px 35px 30px;
  color: #97a4b2;
  font-size: .875em; }
  .forms.pop-forms {
    max-width: 340px;
    margin: auto;
    position: relative; }
    .forms.pop-forms h4 {
      line-height: 1.125em;
      margin-bottom: 18px; }
  .forms h4 {
    color: #fff;
    font-size: 1.8em;
    text-transform: none;
    margin: 0; }
  .forms p, .forms .main-footer .section-head h4, .main-footer .section-head .forms h4, .forms .main-footer .footer-item address, .main-footer .footer-item .forms address {
    line-height: 1.4375em;
    margin-top: 10px; }
  .forms label {
    margin-top: 20px;
    margin-bottom: 15px;
    display: block; }
    .forms label span {
      display: block;
      margin-bottom: 6px; }
  .forms .form-input, .forms textarea {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 3px solid #394b5f;
    background-color: #394b5f;
    text-indent: 10px;
    padding: 10px 0 6px;
    transition: all .5s ease;
    color: #fff; }
    .forms .form-input:focus, .forms textarea:focus {
      background-color: rgba(57, 75, 95, 0.5);
      border-bottom: 3px solid #589a36; }
  .forms .buttons {
    margin-top: 15px; }
  .forms textarea {
    resize: none;
    min-height: 85px; }
  .forms select {
    outline: none; }

.mfp-forms .forms {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
  border-radius: 5px; }

.mfp-forms.mfp-wrap.mfp-ready {
  opacity: 1; }

.mfp-forms .mfp-close {
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  top: 5px;
  right: 5px;
  transition: opacity 0.25s ease-out; }

.mfp-forms.mfp-bg {
  opacity: 0;
  transition: all 0.25s ease-out; }
  .mfp-forms.mfp-bg.mfp-ready {
    opacity: 0.8; }
  .mfp-forms.mfp-bg.mfp-removing {
    opacity: 0; }

.mfp-forms.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.25s ease-out; }

.mfp-forms.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-forms.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

@media (max-width: 575.98px) {
  .formError {
    left: initial !important;
    right: 0 !important; } }

.formError .formErrorContent {
  background: #2c3949; }
  @media (min-width: 576px) {
    .formError .formErrorContent {
      min-width: 180px; } }
  @media (min-width: 768px) {
    .formError .formErrorContent {
      min-width: 220px; } }

.formError .formErrorArrow div {
  background: #2c3949; }

.main-footer .formError {
  left: initial !important;
  right: 0 !important; }

html, body {
  height: 100%;
  margin: 0 !important; }

body {
  font-size: 1em;
  min-width: 300px;
  position: relative;
  line-height: 1.375em;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  color: #333; }
  body input, body textarea {
    outline: none; }

a {
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    text-decoration: none; }

a, a:hover, a:focus, a:active {
  color: #6ebd45;
  outline: none; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.375em; }

h1 {
  font-size: 3em; }

h2 {
  font-size: 2em; }
  @media (max-width: 767.98px) {
    h2 {
      font-size: 1.8em; } }

h3 {
  font-size: 1.625em; }

.headers, h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-weight: normal;
  color: #2c3949; }

.hidden {
  display: none; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.text-shadow-1, .lang-switch li a, .header-wrap .header-left, .main-footer {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }

.section, .main-footer {
  padding-top: 65px;
  background-color: #F9F9F9;
  position: relative; }
  .section a:hover, .main-footer a:hover {
    text-decoration: none; }
  .section .section-head, .main-footer .section-head {
    margin-bottom: 65px; }
    .section .section-head p, .main-footer .section-head p, .main-footer .section-head h4, .section .section-head .main-footer .footer-item address, .main-footer .section-head .footer-item address, .main-footer .footer-item .section-head address, .section .section-head .p, .main-footer .section-head .p {
      margin-top: 50px;
      text-align: center;
      animation-delay: .4s; }
      .section .section-head p a, .main-footer .section-head p a, .main-footer .section-head h4 a, .section .section-head .main-footer .footer-item address a, .main-footer .section-head .footer-item address a, .main-footer .footer-item .section-head address a, .section .section-head .p a, .main-footer .section-head .p a {
        font-family: "RalewayBold", sans-serif; }
    .section .section-head h2, .main-footer .section-head h2 {
      text-align: center;
      position: relative;
      display: inline-block; }
      .section .section-head h2::after, .main-footer .section-head h2::after {
        position: absolute;
        content: '';
        height: 4px;
        background-color: #589a36;
        width: 100%;
        display: block;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0); }
    .section .section-head h3, .main-footer .section-head h3 {
      text-align: center;
      margin-top: 50px; }
  .section .section-bottom, .main-footer .section-bottom {
    background-color: #D6D6D6;
    padding: 50px 0;
    text-align: center; }
    .section .section-bottom .buttons, .main-footer .section-bottom .buttons {
      font-size: 1.25em; }

.section-head {
  width: 100%;
  text-align: center; }

.section-left .section-head {
  margin-bottom: 50px; }
  .section-left .section-head h2 {
    margin-top: 0;
    text-align: left; }
    .section-left .section-head h2::after {
      transform: none;
      left: 0; }
  .section-left .section-head p, .section-left .main-footer .section-head h4, .main-footer .section-left .section-head h4, .section-left .section-head .main-footer .footer-item address, .main-footer .footer-item .section-left .section-head address {
    text-align: left; }

.section_profit {
  padding-top: 0; }
  .section_profit .section-head {
    margin-top: 65px; }

.section-news .section-content, .section_tools .section-content {
  background-image: url(../img/s1_gray_bg.png);
  background-repeat: no-repeat;
  background-position: bottom; }

.section-news .section-bottom, .section_tools .section-bottom {
  background-color: #2C3949; }

.section_tools .col-img img {
  width: 100%;
  height: auto; }

.section-content {
  position: relative;
  padding-bottom: 90px; }

.profit-items {
  padding-bottom: 20px; }
  .profit-items .profit-item {
    text-align: center;
    color: #111;
    margin-bottom: 75px;
    display: block;
    transition: all .25s ease; }
    .profit-items .profit-item:hover {
      color: #589a36; }
      .profit-items .profit-item:hover h4 {
        color: #589a36; }
    .profit-items .profit-item > span {
      display: block; }
    .profit-items .profit-item h4 {
      font-weight: 600; }
    .profit-items .profit-item-text {
      font-size: 0.875em;
      line-height: 1.25em;
      font-weight: 500; }
  .profit-items .img-wrap {
    padding-bottom: 32px;
    margin-bottom: 15px;
    display: block;
    position: relative;
    line-height: 60px;
    height: 100px; }
    .profit-items .img-wrap::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #589a36;
      width: 58px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0); }
    .profit-items .img-wrap img {
      max-width: 100px;
      max-height: 80px;
      width: auto; }

.img-wrap .img-fluid {
  width: 100%;
  max-width: 100%; }

.profit-items-container {
  text-align: center;
  padding-top: 25px; }

.profit-item-wrap, .section_clients .clients-item-wrap {
  display: inline-block;
  float: none;
  padding: 0 15px;
  vertical-align: top;
  opacity: 0;
  transition: all .5s ease;
  transform: translateY(-25px); }
  .profit-item-wrap.on, .section_clients .on.clients-item-wrap {
    opacity: 1;
    transform: translateY(0); }
  .profit-item-wrap:hover, .section_clients .clients-item-wrap:hover {
    transform: translateY(-10px); }
  @media (min-width: 1200px) {
    .profit-item-wrap, .section_clients .clients-item-wrap {
      width: 365px; } }

.section_clients .img-wrap {
  height: 140px; }
  .section_clients .img-wrap img {
    max-width: 260px;
    max-height: 100px;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }

.section_clients .clients-items-container {
  padding-top: 50px;
  text-align: center; }

.section_clients .clients-item-wrap:hover {
  transform: none; }

.s_dark {
  background-color: #394b5f; }
  .s_dark .section-head h2 {
    color: #fff;
    font-weight: 200; }
  .s_dark .section-head p, .s_dark .main-footer .section-head h4, .main-footer .s_dark .section-head h4, .s_dark .section-head .main-footer .footer-item address, .main-footer .footer-item .s_dark .section-head address {
    color: #e5e5e5; }
  .s_dark .section-left p, .s_dark .section-left .main-footer .section-head h4, .main-footer .section-head .s_dark .section-left h4, .s_dark .section-left .main-footer .footer-item address, .main-footer .footer-item .s_dark .section-left address {
    color: #e5e5e5; }
  .s_dark .section-bottom {
    background-color: #2c3949;
    margin-top: 60px; }
  .s_dark .profit-item {
    color: #e5e5e5; }

.s_gray {
  background-color: #E5E5E5; }
  .s_gray .section-bottom {
    background-color: #D6D6D6;
    margin-top: 60px; }

.section-right {
  padding-top: 30px; }
  .section-right .section-head, .section-right .section-head p, .section-right .main-footer .section-head h4, .main-footer .section-right .section-head h4, .section-right .section-head .main-footer .footer-item address, .main-footer .footer-item .section-right .section-head address, .section-right p, .section-right .main-footer .section-head h4, .main-footer .section-head .section-right h4, .section-right .main-footer .footer-item address, .main-footer .footer-item .section-right address {
    text-align: right; }
  @media (min-width: 768px) {
    .section-right {
      padding-top: inherit; } }
  .section-right li:after {
    right: -5px;
    content: "—";
    position: relative;
    color: #589a36; }

.section-left {
  padding-bottom: 30px; }
  .section-left .section-head, .section-left .section-head p, .section-left .main-footer .section-head h4, .main-footer .section-left .section-head h4, .section-left .section-head .main-footer .footer-item address, .main-footer .footer-item .section-left .section-head address, .section-left p, .section-left .main-footer .section-head h4, .main-footer .section-head .section-left h4, .section-left .main-footer .footer-item address, .main-footer .footer-item .section-left address {
    text-align: left; }
  .section-left li:before {
    left: -5px;
    content: "—";
    position: relative;
    color: #589a36; }
  @media (min-width: 768px) {
    .section-left {
      padding-left: 40px; } }

.section-pb {
  padding-bottom: 70px; }

.section_scheme.s_dark {
  background-color: #394b5f; }

.section_scheme .scheme-items {
  padding-bottom: 50px; }
  @media (min-width: 768px) {
    .section_scheme .scheme-items {
      padding-bottom: 0; } }

.section_scheme .img-wrap {
  height: auto; }

@media only screen and (max-width: 768px) {
  /*Disable Animation on Mobile Devices*/
  .animated {
    transition-property: none !important;
    transform: none !important;
    animation: none !important; } }

@media only screen and (max-width: 992px) {
  .header-wrap {
    margin-top: 0; }
    .header-wrap h1 {
      margin-top: 0; }
  .main-menu {
    top: 50px; }
  .top-line {
    min-height: 140px; } }
