
.tools {
    .main-head {
        min-height: 300px;
        height: initial; }
    .tools-head {
        margin-bottom: 20px;
        [class^="col"] {
            &:last-child {
                display: flex;
                justify-content: flex-end;
                align-items: center; } } }
    .header-wrap {
        p {
            &.anim {
                transition-delay: .5s; } } }
    .card {
        color: $main-font-color-b;
        background-color: $s_gray;
        h3 {
            color: $header-color; }
        a {
            font-weight: bold;
            color: $accent; }
        //.img-wrap
        //    height: 100%
 }        //max-height: 465px
    //.col-img
    //    .img-wrap
    //        //height: auto
    //        img
    //            height: auto
    //            width: 100%
    .profit-items-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .profit-item-wrap {
            width: 55%;

            @include media-breakpoint-up(sm) {
                width: 40%; }
            @include media-breakpoint-up(md) {
                width: 25%; }
            .img-wrap {
                height: 90px; }
            img {
                max-height: 70px; }
            span + span {
                max-width: 170px;
                margin: 0 auto;
                @include media-breakpoint-down(md) {
                    max-width: initial; } } } } }
.tools-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 5px;
    h4, h3 {
        font-weight: 500;
        //text-align: right
        border-bottom: 1px solid $main-color;
        color: $main-color;
        /* text-transform: initial; */
        margin-bottom: 10px;
        padding-bottom: 10px;
        text-shadow: 0 0 1px rgba(0, 0, 0, 0.1); }
    p , ul {
        margin-bottom: .5rem; }
    i.fa {
        color: $main-color; }
    ul {
        list-style: none;
        list-style-position: outside;
        //text-align: right
        padding-left: 5px;
        padding-right: 5px; }
    strong {
        color: $main-color; }
    &.s_dark {
        background-color: $headers-color; } }
.s_dark {
    .tools-items {
        h4, h3 {
            color: $main-color-2; }
        p {
            color: $main-font-color-dw;
            font-weight: 300; } } }
