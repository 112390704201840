
.buttons {
    display: inline-block;
    border: none;
    background-color: $main-color;
    color: $main-font-color-w;
    font-size: 1.2em;
    padding: 12px 30px;
    border-bottom: $header-color 3px solid;
    transition: all .25s ease;
    text-decoration: none;
    border-radius: 2px;
    &:hover, &:active, &:focus {
        text-decoration: none;
        background-color: $headers-color;
        color: $main-font-color-w;
        border-bottom-color: $main-color; } }

.btn-fallback {
    border: 1px solid white;
    text-decoration: none;
    color: white;
    background: none;
    font-size: inherit;
    transition: all 0.25s ease-in;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    padding: 8px 20px;
    line-height: 1.42857143;
    border-radius: 4px;
    user-select: none;
    &:hover {
        //border-color: $main-color
        background-color: $main-color;
        color: white; }
    .fa {
        margin-right: 7px; } }
.button-wrap {
    animation-delay: .2s;
    animation-duration: .5s; }
