
.before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }

.trans {
    transition: all .5s ease; }

.main-head {
    min-height: 620px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 480px) {
        height: 100vh; }
    &::before {
        @extend .before;
        background-color: $headers-color;
        opacity: .5; }
    h1 {
        text-transform: none;
        line-height: 1.125em; } }

.top-line {
    padding-top: 20px;
    min-height: 80px;
    //background-image: url(../img/top_gradient.png)
    //background-repeat: repeat-x
    background: linear-gradient(black, rgba(255,0,0,0));
    .row {
        position: relative; }
    @include media-breakpoint-down(sm) {
        padding-top: 10px; } }

.logo {
    opacity: .95;
    img {
        width: 200px;
        @media only screen and (max-width : 992px) and (min-width: 768px) {
            width: 150px; }
        @media only screen and (max-width : 576px) {
            margin: {
                top: 30px;
                bottom: 30px; } } } }

.main-menu {
    @media only screen and (max-width : 768px) {
        display: none;
        float: right;
        min-width: 295px;
        text-align: right;
        background-color: rgba(44, 57, 73, 0.8);
        top: 10px;
        border: #111821 1px solid;
        //border-bottom: #111821 4px solid
        //border-top: #232D3B 4px solid
        position: absolute;
        right: 15px;
        z-index: 2;
        ul {
            //display: block
            padding-top: 50px!important;
            li {
                display: block;
                line-height: 0;
                padding: 0;
                &.active {
                    a {
                        background-color: #273646;
                        text-decoration: none;
                        font-family: "Roboro", sans-serif;
                        font-weight: 700; } } }
            a {
                display: block;
                line-height: 48px;
                background-color: transparent;
                font-size:  0.9375em;
                text-transform: uppercase;
                transition: all .5s ease;
                border-bottom: #222C36 1px solid;
                padding: 10px!important;
                &:hover {
                    background-color: #273646;
                    text-decoration: none; } } } } }

.menu-style {
    font-size: 0.9375em;
    li {
        display: inline-block;
        &.active {
            a {
                color: $main-color-2;
                text-decoration: underline; } }
        a {
            transition: all .5s ease;
            line-height: 2.5;
            padding: 15px;
            &:hover {
                color: $main-color;
                text-decoration: underline; } } }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex; }
    a {
        text-decoration: none;
        color: $main-font-color-w; }
    @media only screen and (max-width : 992px) and (min-width: 768px) {
        font-size: .8em;
        ul {
            justify-content: flex-start; } } }

.main-side-menu {
    li {
        order: 1; }
    .menu-login {
        @media only screen and (max-width : 576px) {
            order: 0; } } }

.lang-switch {
    padding: 0 15px;
    z-index: 100;
    margin-top: 25px;
    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: -80%;
        right: 0; }
    @include media-breakpoint-up(sm) {
        margin-top: 10px; }
    @include media-breakpoint-up(md) {
        margin-top: 20px; }
    li {
        a {
            font-weight: 500;
            @extend .text-shadow-1; } }
    li.active {
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline; } } } }

.toggle-mnu-wrap {
    position: relative;
    //.phone
    //    opacity: 0.9
    //    color: #fff
    //    font-family: "RobotoBold", sans-serif
    //    font-size: 1.25em
    //    line-height: 48px
    //    text-align: right
    //    white-space: nowrap
    .toggle-mnu {
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        line-height: 48px;
        @include media-breakpoint-down(sm) {
            .toggle-mnu {
                line-height: 34px;
                margin-top: 8px; } } } }

.toggle-mnu {
    display: block;
    width: 28px;
    height: 28px;
    margin-top: 14px; }

.toggle-mnu span:after,
.toggle-mnu span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }

.toggle-mnu span:after {
    top: 18px; }

.toggle-mnu span {
    position: relative;
    display: block; }

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }

.toggle-mnu.on span {
    background-color: transparent; }

.toggle-mnu.on span:before {
    transform: rotate(45deg) translate(-1px, 0px); }

.toggle-mnu.on span:after {
    transform: rotate(-45deg) translate(6px, -7px); }

.toggle-mnu.on + #menu {
    opacity: 1;
    visibility: visible; }

.header-wrap {
    display: flex;
    .header-left {
        position: relative;
        z-index: 0;
        @extend .text-shadow-1;
        padding: {
            top: 30px;
            bottom: 30px; }
        .btn-fallback {
            background-color: $main-color;
            &:hover {
                background-color: transparent; } }
        &:after {
            content: '';
            background: linear-gradient(to right, rgb(57, 75, 95), rgba(57, 75, 95, 0.8), rgba(57, 75, 95, 0.65));
            opacity: 0.9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            filter: blur(30px); } }
    .header-title, .header-subtitle {
        color: $main-font-color-w; }
    .header-title {
        padding-bottom: 8px;
        font-weight: 100;
        font-size: 2.7em;
        @include media-breakpoint-up(lg) {
            font-size: 2.9em; }
        strong {
            font-weight: 500;
            transform: translate3d(0, -100px, 0);
            &.anim {
                transition-delay: .2s; } }
        span {
            transform: translate3d(0, -150px, 0);
            &:first-child {
                font-size: 120%; }
            @include media-breakpoint-up(lg) {
                &:first-child {
                    font-size: 150%; } }
            &.anim {
                transition-delay: .4s; } }
        strong, span {
            transition: transform 1s ease, opacity 1s ease;
            display: inline-block;
            opacity: 0;
            &.anim {
                opacity: 1;
                transform: translate3d(0, 0, 0); } }

        &::after {
            position: relative;
            content: '';
            height: 6px;
            background-color: $main-color;
            width: 100%;
            display: block;
            margin-top: 15px; } }
    .button-wrap {
        opacity: 0;
        transform: translate3d(0, 150px, 0);
        transition: transform 1.4s ease, opacity 1.4s ease;
        &.anim {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition-delay: .8s; } }
    .header-subtitle {
        font-weight: 300;
        transition: transform 1s ease, opacity 1s ease;
        transform: translate3d(0, 80px, 0);
        opacity: 0;
        &.anim {
            opacity: 1;
            transition-delay: .6s;
            transform: translate3d(0, 0, 0); } }
    p {
        color: $main-font-color-w;
        margin-top: 10px;
        font-weight: 300;
        opacity: 0;
        transition: transform 1s ease, opacity 1s ease;
        transform: translate3d(0, 80px, 0);
        a {
            font-weight: 500; }
        &.anim {
            opacity: 1;
            transition-delay: .8s;
            transform: translate3d(0, 0, 0); } }
    .buttons {
        padding: 12px 50px; } }

.arrow-wrap {
    text-align: center;
    z-index: 2;
    width: 100%;
    position: relative;
    padding: 2.4em 0 4em; }

$breakpoint-tablet: 768px;
.arrow-bottom {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url(../img/arrow_down.png);
    cursor: pointer;
    opacity: .7;
    transition: opacity .25s ease;
    @media (max-width: $breakpoint-tablet) {
        display: none; }
    &:hover {
        opacity: 1; } }



