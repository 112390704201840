
::selection {
    //background-color: $accent
 }    //color: #fff

* {
    &::-webkit-input-placeholder {
        @include mix-placeholder(); }
    &:-moz-placeholder {
        @include mix-placeholder(); }
    &::-moz-placeholder {
        @include mix-placeholder(); }
    &:-ms-input-placeholder {
        @include mix-placeholder(); } }

.forms {
    &.pop-forms {
        max-width: 340px;
        margin: auto;
        position: relative;
        h4 {
            line-height: 1.125em;
            margin-bottom: 18px; } }
    background-color: $header-color;
    padding: 35px 35px 30px;
    color: $form-color;
    font-size: .875em;
    h4 {
        color: #fff;
        font-size: 1.8em;
        text-transform: none;
        margin: 0; }
    p {
        line-height: 1.4375em;
        margin-top: 10px; }
    label {
        margin-top: 20px;
        margin-bottom: 15px;
        display: block;
        span {
            display: block;
            margin-bottom: 6px; } }
    .form-input {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 3px solid $headers-color;
        background-color: $headers-color;
        text-indent: 10px;
        padding: 10px 0 6px;
        transition: all .5s ease;
        color: #fff;
        //font-size: 1.25em
        &:focus {
            background-color: transparentize($headers-color, .5);
            border-bottom: 3px solid $main-color; } }
    .buttons {
        margin-top: 15px; }
    textarea {
        @extend .form-input;
        resize: none;
        min-height: 85px; }
    select {
        outline: none; } }

.mfp-forms {
    .forms {
        box-shadow: 0 0 18px rgba(0,0,0,.5);
        border-radius: 5px; }
    &.mfp-wrap {
        &.mfp-ready {
            opacity: 1; } }
    .mfp-close {
        color: #fff;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        border: 1px solid #fff;
        top: 5px;
        right: 5px;
        transition: opacity 0.25s ease-out; }
    &.mfp-bg {
        opacity: 0;
        transition: all 0.25s ease-out;
        &.mfp-ready {
            opacity: 0.8; }
        &.mfp-removing {
            opacity: 0; } }
    &.mfp-wrap {
        .mfp-content {
            opacity: 0;
            transition: all 0.25s ease-out; }
        &.mfp-ready .mfp-content {
            opacity: 1; }
        &.mfp-removing .mfp-content {
            opacity: 0; } } }

.formError {
    @include media-breakpoint-down(xs) {
        left: initial!important;
        right: 0!important; }
    .formErrorContent {
        background: $header-color;
        @include media-breakpoint-up(sm) {
            min-width: 180px; }
        @include media-breakpoint-up(md) {
            min-width: 220px; } }
    .formErrorArrow {
        div {
            background: $header-color; } } }

.main-footer {
    .formError {
        @include media-breakpoint-up(xs) {
            left: initial!important;
            right: 0!important; } } }
